<template>
<div v-if="data.img[0]">
 <a class="item">
         <img  :src="apiUrl + data.img[0].url" :alt="data.title" />
         <h2>{{trimWords(data.title,15)}}</h2>
         <p><i class="fe fe-calendar"></i>{{moment(data.createdAt).format("DD/MM/YYYY")}}</p>
         <div class="description">
            <p>{{trimWords(data.desc,20)}}</p>
         </div>
         <div class="stats">
            <span>{{data.img.length}} <i class="fe fe-image"></i> </span>
         </div>
      </a>
</div>
</template>
<script>
import moment from 'moment'
export default {
  name: 'AlbumCard',
  props: {
      data: {type: Object, default: null},
    },
  data: () => ({
     apiUrl: process.env.VUE_APP_API_URL,
  }),
  methods: {
    moment,
		trimWords(value,number){
      if(!value) return ""
      if(value.length < number) return value
			return value.split(" ").splice(0,number).join(" ") + '...';
		},
    ToText(HTML)
    {
        var input = HTML;
        return input.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi,'').replace(/<[^>]+?>/g,'').replace(/\s+/g,' ').replace(/ /g,' ').replace(/>/g,' ');  
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/mixins.scss';
.gallery {
   padding: 20px;
   text-align: center;
   max-width: 1200px;
   margin: auto;
}

.item {
   display: inline-block;
   text-decoration: none;
   background: $white;
   margin: 10px;
   width: 260px;
   height: 360px;
   -webkit-box-shadow: 2px 2px 2px 0px rgba(212, 212, 212, 1);
   -moz-box-shadow: 2px 2px 2px 0px rgba(212, 212, 212, 1);
   box-shadow: 2px 2px 2px 0px rgba(212, 212, 212, 1);
   position: relative;
}

.item img{
   width: 260px;
   height: 200px;
   object-fit: cover;
}

.item .stats {
   font-size: 13px;
   position: absolute;
   bottom: 15px;
   right: 10px;
     color: $primary;
}

.item .stats i {
   transition: color 250ms;
   -webkit-transition: color 250ms;
   -moz-transition: color 250ms;
   -o-transition: color 250ms;
}

.item h2 {
    border-top: 2px solid $primary;
   padding: 10px;
   padding-right: 21px;
   padding-bottom: 0;
   height: 110px;
   font-size: 20px;
      color: $secondary;
}

.item p {
   font-size: 15px;
   padding: 10px;
   color: silver;
}

.item .description {
   display: block;
   position: absolute;
   top: 0;
   left: 0;
   color: #eee;
   height: 200px;
   background: rgba(158, 0, 0, 0.774);
   width: 0;
   padding: 0;
   overflow: hidden;
   transition: width 500ms;
   -webkit-transition: width 500ms;
   -moz-transition: width 500ms;
   -o-transition: width 500ms;
}

.item .description p {
   width: 220px;
   display: block;
   padding: 20px;
   
}

.item:hover .description {
   width: 260px;
   
}

footer {
   color: silver;
   -webkit-box-shadow: 0px -2px 2px 0px rgba(212, 212, 212, 1);
   -moz-box-shadow: 0px -2px 2px 0px rgba(212, 212, 212, 1);
   box-shadow: 0px -2px 2px 0px rgba(212, 212, 212, 1);
   padding: 22px;
   text-transform: uppercase;
   font-weight: bold;
   letter-spacing: 1px;
   font-size: 11px;
}

footer a {
   text-decoration: none;
   color: inherit;
   display: inline-block;
   border-bottom: solid 1px rgba(0, 0, 0, .1);
}

footer a:hover {
   color: #333;
}
</style>